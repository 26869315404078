<template>
	<div id="page">
		<div id="section_wrapper">
			<section ref="about-top">
				<div class="section_content ab_sc fl-c-t">
						<div class="ab_left fl-c">
							<img src="/assets/img/about/me.jpg" alt="Ali Pordeli">
						</div>
						<div class="ab_right fl-co-l fl-g">
							<div class="ab_top">
								<div class="ab_q upper" v-html="about.heading"></div>
								<h1 class=" ab_headname">Ali Pordeli</h1>
							</div>
							<div class="ab_text">
								<p v-for="(t,i) in about.text" :key="i" v-html="t"></p>
							</div>
							<div class="ab_list">
								<div class="ab_li-item fl-co-l" v-for="(item,i) in about.list" :key="i">
									<h3 class="ab_li-title upper">{{ item.title }}</h3>
									<div class="ab_li" v-for="(li,ii) in item.content" :key="i+'-'+ii">
										<div class="ab_li-text">{{ li.text }}</div>
										<span v-if="li.info">&nbsp;</span>
										<div v-if="li.info" class="ab_li-info">{{ li.info }}</div>
										<span>&nbsp;</span>
										<div class="ab_li-date">{{ li.date }}</div>
									</div>
								</div>
							</div>
						</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'



export default {
  name: "About",
	mounted() {
		this.setSections([this.$refs, this.$route.name])
  },
  computed: {
    ...mapGetters("PagesStore", ['getPageInfo']),
    about() { return this.getPageInfo('about')}
  },
	methods: {
		...mapActions("GeneralStore", ['setSections','updateCursorEl']),
	},
}
</script>